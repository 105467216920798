import React, { FunctionComponent, PropsWithChildren } from 'react'
import { isClient } from '@wh/common/chapter/lib/commonHelpers'

interface ScrollContainerContextProps extends PropsWithChildren {
    scrollElement: null | HTMLElement | Window
    additionalTopThreshold?: number
}

export const ScrollContainerContext = React.createContext<ScrollContainerContextProps>({
    scrollElement: isClient() ? window : null,
    additionalTopThreshold: 0,
})

export const ScrollContainerProvider: FunctionComponent<ScrollContainerContextProps> = ({
    scrollElement,
    children,
    additionalTopThreshold,
}) => {
    return <ScrollContainerContext.Provider value={{ scrollElement, additionalTopThreshold }}>{children}</ScrollContainerContext.Provider>
}
