import { Navigator } from '@bbx/search-journey/common/Navigators'
import { SearchCallback } from '@bbx/search-journey/sub-domains/search/components/common/common/SearchCallback'
import { useMultiSelectCheckboxState } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/MultiSelectNavigator/useMultiSelectCheckboxState'
import React, { useState } from 'react'
import { buildAdditionalParamsFromCheckboxes } from '@bbx/search-journey/sub-domains/search/lib/navigator-functions'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { TaggingData } from '@wh/common/chapter/types/taggingData'

export const useModalNavigator = (navigator: Navigator | undefined, onSearch: SearchCallback, taggingData: TaggingData) => {
    const { checkboxState, setCheckboxState, handleChange } = useMultiSelectCheckboxState(navigator)

    const [checkBoxStateBeforeModalOpen, setCheckBoxStateBeforeModalOpen] = useState<Record<string, boolean>>(checkboxState)
    const [modalOpen, setModalOpen] = useState<boolean>(false)

    const isCatless = navigator?.metaTags?.includes('CATEGORY_LESS')

    const onSubmitMultiSelect = async (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) => {
        if (navigator) {
            e.preventDefault()
            const additionalParams = buildAdditionalParamsFromCheckboxes(navigator, checkboxState)

            await onSearch(navigator.urlConstructionInformation.baseUrl, additionalParams)
            setModalOpen(false)
            if (isCatless) {
                callActionEvent('search_result_list_catless_search', taggingData)
            }
        }
    }

    const openModal = () => {
        if (isCatless) {
            callActionEvent('search_result_list_catless_open', taggingData)
        }
        setCheckBoxStateBeforeModalOpen(checkboxState)
        setModalOpen(true)
    }

    const closeModal = () => {
        setModalOpen(false)
        setCheckboxState(checkBoxStateBeforeModalOpen)
    }
    return {
        checkboxState,
        setCheckboxState,
        handleChange,
        onSubmitMultiSelect,
        modalOpen,
        openModal,
        closeModal,
    }
}
