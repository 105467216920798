import React, { FunctionComponent, useState } from 'react'
import { Modal } from '@wh-components/core/Modal/Modal'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import { Box } from '@wh-components/core/Box/Box'
import { Button } from '@wh-components/core/Button/Button'
import { BudgetCalculator } from '@bbx/search-journey/sub-domains/search/components/common/common/BudgetCalculator/BudgetCalculator'

export const BudgetCalculatorModal: FunctionComponent<{
    isOpen: boolean
    onRequestClose: () => void
    taggingData: TaggingData
    setToPrice: (price: string) => void
}> = ({ isOpen, onRequestClose, taggingData, setToPrice }) => {
    const FOOTERSUBTEXT =
        'Die Berechnung des maximal möglichen Kaufpreises erfolgt auf Basis der angegebenen Daten und eines fiktiven Zinssatzes ' +
        'von 4% per anno. Fiktive Zinsen werden von Banken für ihre Zins-Kalkulationen verwendet und sind eine Vorsichtsmaßnahme, ' +
        'die berücksichtigt, dass variable Zinsen über die Jahre ansteigen können.'
    const [price, setPrice] = useState('')

    return (
        <Modal
            header="Kaufbudgetrechner"
            width={{ phone: 'auto', tablet: '720px' }}
            maxWidth={{ desktop: '568px' }}
            onRequestClose={onRequestClose}
            isOpen={isOpen}
            fullScreen={{ phone: true, tablet: false }}
            testId="budgetCalculator-modal"
            footer={
                <Box display="flex" justifyContent="space-between" paddingTop="s" paddingBottom="0" borderTop="owl">
                    <Button
                        display={{ tablet: 'none' }}
                        flex={{ phone: '1 1 50%', tablet: 'inherit' }}
                        minWidth={{ tablet: 150 }}
                        marginRight="m"
                        color="complimentary"
                        testId="budgetCalculator-dismiss-modal"
                        onClick={onRequestClose}
                        size={{ phone: 'large', tablet: 'medium' }}
                    >
                        Abbrechen
                    </Button>
                    <Button
                        flex={{ phone: '1 1 50%', tablet: 'inherit' }}
                        marginLeft="auto"
                        minWidth={{ tablet: 150 }}
                        testId="budgetCalculator-submit-modal"
                        onClick={() => {
                            setToPrice(price)
                        }}
                        size={{ phone: 'large', tablet: 'medium' }}
                    >
                        Übernehmen
                    </Button>
                </Box>
            }
        >
            <BudgetCalculator
                taggingData={taggingData}
                disabledHeader={true}
                disableSiteMutationStyles={true}
                disableFooter={true}
                footerSubtext={FOOTERSUBTEXT}
                setToPriceToBudget={setPrice}
            />
        </Modal>
    )
}
