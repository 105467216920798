import {
    Navigator,
    NavigatorGroup,
    NavigatorId,
    NavigatorValue,
    PossibleNavigatorValue,
    PossibleValueGroup,
    RangeNavigator,
} from '@bbx/search-journey/common/Navigators'
import { SearchResult } from '@bbx/search-journey/common/SearchResult'

export type CheckboxStateType = Record<string, boolean>

export const getRangeNavigatorById = (searchResult: SearchResult, id: NavigatorId) => {
    const navigator = getNavigatorById(searchResult, id)
    return navigator && isRangeNavigator(navigator) ? navigator : undefined
}

export const getNavigatorById = (searchResult: SearchResult, id: NavigatorId) => {
    return flattenNavigators(searchResult.navigatorGroups).filter((navigator) => navigator.id === id)[0]
}

export const getNavigatorByIdFromGroups = (navigatorGroups: NavigatorGroup[], id: string) => {
    return flattenNavigators(navigatorGroups).find((navigator) => navigator.id === id)
}

export const getNavigatorByIdFromGroup = (navigatorGroup: NavigatorGroup, id: string) => {
    return navigatorGroup.navigatorList.find((navigator) => navigator.id === id)
}

export const isNavigatorAvailable = (navigator: Navigator): boolean =>
    isMultiOrSingleSelectAvailable(navigator) ||
    isTextNavigator(navigator) ||
    isRangeNavigatorAvailable(navigator) ||
    isAutocompleteNavigator(navigator) ||
    isDisabledCategoryNavigatorAvailable(navigator)

export const isMultiOrSingleSelectAvailable = (navigator: Navigator) =>
    (isSingleSelectNavigator(navigator) || isMultiSelectNavigator(navigator)) &&
    (containsAnyPossibleValue(navigator) || navigator.selectedValues.length > 0)

export const isRangeNavigatorAvailable = (navigator: Navigator | RangeNavigator): navigator is RangeNavigator => isRangeNavigator(navigator)

export const isSingleSelectNavigator = (navigator: Navigator) =>
    navigator.navigatorSelectionType === 'SINGLE_SELECT' && !isRangeNavigator(navigator)

export const isMultiSelectNavigator = (navigator: Navigator) =>
    navigator.navigatorSelectionType === 'MULTI_SELECT' && !isRangeNavigator(navigator)

export const isTextNavigator = (navigator: Navigator) => navigator.navigatorType === 'TEXT_SEARCH'

export const isDisabledCategoryNavigatorAvailable = (navigator: Navigator) =>
    navigator.id === 'category' && navigator.selectedValues.length > 0 && navigator.navigatorSelectionType === 'NOT_SELECTABLE'

export const isRangeNavigator = (navigator: Navigator | RangeNavigator): navigator is RangeNavigator => navigator.navigatorType === 'RANGE'

export const isAutocompleteNavigator = (navigator: Navigator) => navigator.id === 'location-autocomplete'

export const isTreeAttributeNavigator = (navigator: Navigator) =>
    navigator.urlConstructionInformation.urlParams[0].urlParameterName === 'treeAttributes'

export const buildAdditionalParamsFromCheckboxesHardcodedStatic = (
    navigator: Navigator | RangeNavigator,
    checkboxState: Record<string, boolean>,
) => {
    const possibleValues = getFlatPossibleValues(navigator)
    const urlParamName = possibleValues[0].urlParamRepresentationForValue[0].urlParameterName

    const values = Object.keys(checkboxState).filter((value) => checkboxState[value])

    return { [urlParamName]: values }
}

export const buildAdditionalParamsFromCheckboxes = (navigator: Navigator | RangeNavigator, checkboxState: CheckboxStateType) => {
    const possibleValues = getFlatPossibleValues(navigator)

    const urlParamName = possibleValues[0].urlParamRepresentationForValue[0].urlParameterName

    const values = possibleValues
        .filter((possibleValue) => checkboxState[getValueId(possibleValue)])
        .map((possibleValue) => possibleValue.urlParamRepresentationForValue[0].value)

    return { [urlParamName]: values }
}

export const getFlatPossibleValues = (navigator: Navigator | undefined) => {
    return navigator ? getFlatPossibleValuesForArray(navigator.groupedPossibleValues) : []
}

export const getFlatPossibleValuesForArray = (groupedPossibleValues: PossibleValueGroup[]) => {
    return groupedPossibleValues
        .map((group) => group.possibleValues)
        .reduce((result, nextElement) => result.concat(nextElement), [])
        .filter((value, index, array) => array.findIndex((possibleValue) => getValueId(possibleValue) === getValueId(value)) === index) // deduplication
}

export const navigatorContainsAtLeastXPossibleValues = (navigator: Navigator, minValues: number) => {
    return (navigator.groupedPossibleValues[0]?.possibleValues?.length ?? 0) > minValues
}

export const getTopModels = (modelPossibleValues: PossibleNavigatorValue[] | undefined, n: number) => {
    return modelPossibleValues && n >= 0 ? [...modelPossibleValues].sort(compareModelHits).slice(0, n) : modelPossibleValues
}

const compareModelHits = (modelA: PossibleNavigatorValue, modelB: PossibleNavigatorValue) => {
    return modelB.hits - modelA.hits
}

export const getValueId = (value: NavigatorValue) => {
    return value.urlParamRepresentationForValue[0]?.value ?? value.label
}

export const getNumberOfSelectedNavigators = (navigatorGroups: NavigatorGroup[]) => {
    return flattenNavigators(navigatorGroups)
        .filter((navigator) => navigator?.selectedValues.length > 0)
        .reduce((sum, _b) => sum + 1, 0)
}

export const showHitsLabel = (navigator: Navigator) => {
    return navigator.selectedValues.length === 0 && navigator.navigatorValuesDisplayType !== 'IMAGE' && navigator.id !== 'color'
}

export const containsAnyPossibleValue = (navigator: Navigator) => {
    return navigator.groupedPossibleValues.length !== 0 && navigator.groupedPossibleValues[0].possibleValues.length !== 0
}

export const hasAnySelectedValues = (navigatorGroups: NavigatorGroup[]) => {
    return flattenNavigators(navigatorGroups)
        .filter((navigator) => navigator.id !== 'searchId')
        .some((navigator) => navigator?.selectedValues.length > 0)
}

export const flattenNavigators = (navigatorGroups: NavigatorGroup[]) => {
    return navigatorGroups.map((group) => group.navigatorList).reduce((a, b) => a.concat(b), [])
}
