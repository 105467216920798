import { removeUndefined } from '@wh/common/chapter/lib/functionalHelpers'

export interface BudgetCalculatorFormValues {
    netIncome: string
    ownFunds: string
    withAgentCosts: boolean
    variableOrFix: 'fixed' | 'variable'
}

export type BudgetFormField = keyof BudgetCalculatorFormValues
export type BudgetFormValidationErrors = { [key in BudgetFormField]?: string }
export const editBudgetFieldRequiredness = (): Record<BudgetFormField, boolean> => {
    return {
        netIncome: false,
        ownFunds: false,
        withAgentCosts: false,
        variableOrFix: false,
    }
}

export const validateBudgetFormWithErrors = (): BudgetFormValidationErrors => {
    return removeUndefined({
        netIncome: undefined,
        ownFunds: undefined,
        withAgentCosts: undefined,
        variableOrFix: undefined,
    })
}

export const fieldToLabelMap: Record<string, string> = {
    netIncome: 'Nettohaushaltseinkommen pro Monat',
    ownFunds: 'Eigenmittel',
    withAgentCosts: 'inkl. Maklergebühren',
    variableOrFix: '',
}
