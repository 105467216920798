import React, { FunctionComponent } from 'react'
import { Modal } from '@wh-components/core/Modal/Modal'
import { Text } from '@wh-components/core/Text/Text'

export const MortgageFinancingExampleModal: FunctionComponent<{ isOpen: boolean; onRequestClose: () => void }> = ({
    isOpen,
    onRequestClose,
}) => {
    return (
        <Modal
            header="Finanzierungsbeispiel"
            maxWidth="518px"
            onRequestClose={onRequestClose}
            isOpen={isOpen}
            fullScreen={{ phone: true, tablet: false }}
        >
            <Text fontSize="s" color="palette.grey">
                Die Berechnung des maximal möglichen Kaufpreises erfolgt auf Basis der angegebenen Daten und eines fiktiven Zinssatzes von
                4,1% per anno. Fiktive Zinsen werden von Banken für ihre Zins-Kalkulationen verwendet und sind eine Vorsichtsmaßnahme, die
                berücksichtigt, dass variable Zinsen über die Jahre ansteigen können.
                <br />
                Finanzierungsbeispiel
                <br />
                Repräsentatives Beispiel für einen Kreditvertrag mit einem Kreditbetrag von Euro 240.000,- besichert mit einer Hypothek
                Laufzeit 30 Jahre, Zinssatz 4 % p.a. variabel, effektiver Zinssatz, 4,488 % p.a. Einberechnete Kosten (jeweils einmalig vom
                Kreditbetrag): Bearbeitungsentgelt 3 %, Schätzungsgutachten 0,25 % und Grundbucheintragsgebühr 1,2 %, Kontoführungsgebühr
                mtl. Euro 6,78- 360 monatliche Raten zu Euro 1.160,28- zu zahlender Gesamtbetrag Euro 417.699,67, tatsächlicher
                Auszahlungsbetrag Euro 229.320,-. <br />
                Bei den vorliegenden Informationen handelt es sich nicht um eine Europäische Standardinformation für Kredite (ESIS
                Merkblatt) gemäß § 8 Hypothekar- und Immobilienkreditgesetz (HIKrG) und auch um kein verbindliches Angebot im Sinne des §12
                HIKrG. Die Berechnung ersetzt nicht eine persönliche Beratung und die angeführten Konditionen sind änderbar. Sämtliche Werte
                verstehen sich als unverbindliche Richtwerte und sind von Bonität, Kredithöhe, Laufzeit, Verwendungszweck und Besicherung
                abhängig.
            </Text>
        </Modal>
    )
}
