import { findContextLinkWithId } from '@bbx/common/types/contextLinks'
import { SearchResult } from '@bbx/search-journey/common/SearchResult'

// TODO pass navigators directly client side on client routing as soon as the BBX detail search is live with 100% of users
export const detailSearchUrl = (searchResult: SearchResult) => {
    const seoLink = findContextLinkWithId('resultListSeoNavigatorLink', searchResult.searchContextLinks)
    const selfLink = findContextLinkWithId('selfLink', searchResult.searchContextLinks)

    if (!seoLink) {
        return ''
    }
    const [path] = (seoLink.relativePath || '').split('?')
    // take the query from the self link cause this contextlink always contains the search-params in the query
    const [, query] = (selfLink?.relativePath || '').split('?')

    const firstThreePathSegments = `/iad${path}`.split('/').slice(0, 4).join('/')
    return `${firstThreePathSegments}/detailsuche${query ? `?${query}` : ''}`
}
