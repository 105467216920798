import React, { FunctionComponent, useState } from 'react'
import { Button } from '@wh-components/core/Button/Button'
import { useIsomorphicLayoutEffect } from '@wh/common/chapter/hooks/useIsomorphicLayoutEffect'

export const DisabledOnSSRButton: FunctionComponent<React.ComponentProps<typeof Button>> = (props) => {
    const [disabledOverride, setDisabledOverride] = useState(true)

    // we're not using useEffect because it unmasks a flickity issue that has a severe performance impact
    useIsomorphicLayoutEffect(() => {
        setDisabledOverride(false)
    }, [])

    const { disabled, children, ...otherProps } = props

    return (
        <Button {...otherProps} disabled={disabledOverride ? true : disabled}>
            {children}
        </Button>
    )
}
