import React, { FunctionComponent, useMemo } from 'react'
import { Box, BoxProps } from '@wh-components/core/Box/Box'
import { NavigatorProps } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/NavigatorProps'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { ResetAllButton } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/ResetAllButton/ResetAllButton'
import { getValueId } from '@bbx/search-journey/sub-domains/search/lib/navigator-functions'
import { Checkbox } from '@wh-components/core/FormElements/Checkbox/Checkbox'
import { NavigatorValue } from './NavigatorValue'
import { css } from 'styled-components'
import { SelectedNavigatorValue } from '@bbx/search-journey/common/Navigators'

export interface SelectedValuesProps {
    showInColumns?: boolean
    sortSelectedValues?: (a: SelectedNavigatorValue, b: SelectedNavigatorValue) => number
}

export const SelectedValues: FunctionComponent<NavigatorProps & SelectedValuesProps & BoxProps> = ({
    navigator,
    onSearch,
    'aria-labelledby': ariaLabelledBy,
    showInColumns,
    sortSelectedValues,
    taggingData,
    ...props
}) => {
    const navigatorLabelId = `navigator-title-${navigator.id}`
    const combinedAriaLabelledBy = [ariaLabelledBy, navigatorLabelId].filter(Boolean).join(' ')
    const sortedSelectedValues = useMemo(
        () => (sortSelectedValues ? navigator.selectedValues.sort(sortSelectedValues) : navigator.selectedValues),
        [navigator.selectedValues, sortSelectedValues],
    )
    return (
        <Box
            display="flex"
            testId={`${navigator.id}-selected-values`}
            aria-labelledby={ariaLabelledBy}
            paddingHorizontal={{ phone: 'm', tablet: 's' }}
            paddingTop={{ phone: 'm', tablet: 's' }}
            flexDirection={showInColumns ? 'row' : 'column'}
            flexWrap={showInColumns ? 'wrap' : undefined}
            {...props}
        >
            {sortedSelectedValues.map((selectedValue) => (
                <Checkbox
                    key={getValueId(selectedValue)}
                    id={`navigator-${navigator.id}-${selectedValue.label}-checkbox`}
                    testId={`navigator-${navigator.id}-${selectedValue.label}-checkbox`}
                    checked={true}
                    onChange={() => {
                        callActionEvent('search_result_list_reset_click_navigators', taggingData)
                        onSearch(selectedValue.resetLink!)
                    }}
                    paddingTop={0}
                    paddingBottom={{ phone: 'm', tablet: 's' }}
                    label={
                        <Box paddingLeft={2} width="100%">
                            <NavigatorValue
                                value={selectedValue}
                                id={navigator.id}
                                aria-labelledby={combinedAriaLabelledBy}
                                showHitsLabel={false}
                            />
                        </Box>
                    }
                    // we use an empty parent context because the Checkbox uses its label as aria-labelledby, and the PossibleValue already contains the parent context

                    css={css`
                        width: ${showInColumns ? '33.33%' : 'inherit'};

                        ${(p) => p.theme.media.only.phone} {
                            width: 100%;
                        }
                    `}
                />
            ))}
            {navigator.selectedValues.length >= 1 && (
                <Box width={showInColumns ? { phone: '100%', tablet: '33.33%' } : undefined} paddingBottom={{ phone: 'm', tablet: 's' }}>
                    <ResetAllButton
                        navigatorId={navigator.id}
                        onSearch={onSearch}
                        contextLink={navigator.resetAllInformation?.resetAllUrl}
                    />
                </Box>
            )}
        </Box>
    )
}
