import React, { FunctionComponent, useRef } from 'react'
import { BaseComboInput, BaseCombinedComboInputProps, ComboInputItem } from '@wh-components/core/FormElements/ComboInput/ComboInput'
import { formatNumber } from '@wh/common/chapter/lib/formatter'
import { useScrollNavigatorInView } from '@bbx/common/hooks/useScrollNavigatorInView'

type ComboInputProps = Omit<BaseCombinedComboInputProps, 'selectionEmphasis' | 'onKeyDownInput' | 'onBlurInput' | 'onFocusInput'> & {
    formatInput?: boolean
}

const beliebigItem = { label: 'Beliebig', value: '' }

export const ComboInput: FunctionComponent<ComboInputProps> = ({ items, formatInput, onChangeInput, onChangeSelect, ...props }) => {
    const ref = useRef<HTMLDivElement | null>(null)
    const { scrollNavigatorInView } = useScrollNavigatorInView(ref)
    const itemsWithBeliebigAndFreeValue =
        props.value === beliebigItem.value || items.some((i) => i.value === props.value)
            ? [beliebigItem, ...items]
            : [
                  beliebigItem,
                  ...withSortedInsertedItem({ label: formatInput ? formatNumber(props.value) : props.value, value: props.value }, items),
              ]

    return (
        <BaseComboInput
            items={itemsWithBeliebigAndFreeValue}
            formatter={formatInput ? formatNumber : undefined}
            selectionEmphasis={true}
            inputMode="numeric"
            pattern="[0-9]*"
            onKeyDownInput={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.keyCode === 13 && e.target instanceof HTMLElement) {
                    e.target.blur()
                }
            }}
            onFocusInput={scrollNavigatorInView}
            onChangeInput={(e) => {
                if (!containsNumbers(e.target.value)) {
                    onChangeInput?.(e)
                }
            }}
            onChangeSelect={onChangeSelect}
            ref={ref}
            {...props}
        />
    )
}

const withSortedInsertedItem = (item: ComboInputItem, items: ComboInputItem[]) => {
    const insertIndex = items.findIndex((i) => parseInt(i.value, 10) > parseInt(item.value, 10))
    if (insertIndex === -1) {
        return [...items, item]
    } else {
        const result = [...items]
        result.splice(insertIndex, 0, item)
        return result
    }
}

const containsNumbers = (value: string) => /\D/g.test(value)
