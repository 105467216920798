import { TaggingData } from '@wh/common/chapter/types/taggingData'
import { useCallback, useEffect } from 'react'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'

export const useBudgetCalculatorTagging = (isInView: boolean, clicked: boolean, submitted: boolean, taggingData?: TaggingData) => {
    useEffect(() => {
        if (isInView) {
            callActionEvent('search_result_list_mortgage_calculator_viewed', taggingData)
        }
    }, [isInView, taggingData])

    const onClick = useCallback(() => {
        if (!clicked) {
            callActionEvent('search_result_list_mortgage_calculator_click', taggingData)
        }
    }, [clicked, taggingData])

    const onBudgetCalcSubmit = useCallback(() => {
        if (!submitted) {
            callActionEvent('search_result_list_mortgage_calculator_submit', taggingData)
        }
    }, [taggingData, submitted])

    return { onClick, onBudgetCalcSubmit }
}
