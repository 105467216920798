import { useCallback, useContext } from 'react'
import { ScrollContainerContext } from '@wh/common/chapter/components/ScrollContainerProvider/ScrollContainerProvider'
import { useScreenSize } from '@wh/common/chapter/components/UserAgentProvider/useUserAgent'
import { FIXED_HEADER_HEIGHT_PHONE } from '@wh/common/chapter/lib/config/constants'
import { scrollDistanceByElement } from '@wh/common/chapter/lib/commonHelpers'

export const useScrollNavigatorInView = (navigatorRef: React.RefObject<HTMLElement>, skipScrollOniOS = true) => {
    const scrollContainerContext = useContext(ScrollContainerContext)
    const screenSize = useScreenSize()
    const scrollNavigatorInView = useCallback(() => {
        if (screenSize === 'phone') {
            // on iOS, the automatic scrolling to the field would conflict with the manual scrolling and result in a bug on the A&M start page - since the automatic scroll works fine, just deactivate the manual one
            if (skipScrollOniOS === false || (!/iphone/i.test(navigator.userAgent) && !/ipad/i.test(navigator.userAgent))) {
                const additionalHeaderOffset = 30 + (scrollContainerContext.additionalTopThreshold ?? 0)
                const viewportTopOffset = navigatorRef.current?.getBoundingClientRect().top ?? 0
                const scrollToTopDistance = viewportTopOffset - (FIXED_HEADER_HEIGHT_PHONE + additionalHeaderOffset)

                scrollDistanceByElement(scrollContainerContext.scrollElement, scrollToTopDistance, 0)
            }
        }
    }, [navigatorRef, screenSize, scrollContainerContext.additionalTopThreshold, scrollContainerContext.scrollElement, skipScrollOniOS])
    return { scrollNavigatorInView }
}
