import React, { FunctionComponent } from 'react'
import { ContextLink } from '@bbx/common/types/contextLinks'
import { ButtonWithLinkStyle } from '@wh-components/core/Button/ButtonWithLinkStyle'
import { SearchCallback } from '@bbx/search-journey/sub-domains/search/components/common/common/SearchCallback'

interface ResetAllButtonProps {
    navigatorId: string
    onSearch?: SearchCallback
    onClick?: () => void
    contextLink?: ContextLink
}

type ButtonProps = React.ComponentProps<typeof ButtonWithLinkStyle>

export const ResetAllButton: FunctionComponent<ResetAllButtonProps & Omit<ButtonProps, 'onClick'>> = ({
    navigatorId,
    onSearch,
    onClick,
    contextLink,
    testId,
    children = 'Zurücksetzen',
    ...props
}) => {
    return contextLink ? (
        <ButtonWithLinkStyle
            {...props}
            testId={testId ? `${testId}-${navigatorId}-reset-all` : `${navigatorId}-reset-all`}
            onClick={() => {
                onSearch?.(contextLink)
                onClick?.()
            }}
        >
            {children}
        </ButtonWithLinkStyle>
    ) : null
}
