import { useCallback, useRef } from 'react'

export const useAbortController = () => {
    const abortControllerRef = useRef<AbortController | undefined>(undefined)

    const createAbortSignal = useCallback(() => {
        const abortController = new AbortController()
        abortControllerRef.current = abortController
        return abortController.signal
    }, [])

    const abort = useCallback(() => {
        abortControllerRef.current?.abort()
    }, [])

    return { createAbortSignal, abort }
}
