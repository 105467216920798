import { getWohnkrediteApiUrl } from '@wh/common/chapter/lib/config/runtimeConfig'

interface MortgageRateResponse {
    monthlyRate: number
    interestRate: number
    costDistribution: {
        interest: number
        ancillaryCost: number
        price: number
    }
    fixedTopInterestRatePeriod?: number
    fixedTopInterestRateString?: string
}

export const getMortgageRate = async (
    price: number,
    ownFunds: number,
    nrOfYears: number,
    variableOrFix: string,
    withAgentCosts: boolean,
): Promise<MortgageRateResponse | undefined> => {
    const controller = new AbortController()
    const timeoutId = setTimeout(() => controller.abort(), 2000)

    const response = await fetch(
        `${getWohnkrediteApiUrl()}/v1/rate?${new URLSearchParams({
            netPrice: `${price}`,
            ownFunds: `${ownFunds}`,
            periodInYears: `${nrOfYears}`,
            interestRate: variableOrFix,
            realEstateAgent: `${withAgentCosts}`,
        })}`,
        {
            headers: { Accept: 'application/json' },
            signal: controller.signal,
        },
    )
    clearTimeout(timeoutId)

    return response.json() as Promise<MortgageRateResponse>
}

interface BudgetResponse {
    maxRealEstatePrice: number
    maxMonthlyRate: number
    period: number
    interestRate: number
    effectiveInterestRate: number
}

export const getBudget = async (
    netIncome: number,
    ownFunds: number,
    withAgentCosts: boolean,
    variableOrFix: string,
): Promise<BudgetResponse | undefined> => {
    const controller = new AbortController()
    const timeoutId = setTimeout(() => controller.abort(), 2000)

    const response = await fetch(
        `${getWohnkrediteApiUrl()}/v1/budget?${new URLSearchParams({
            netIncome: `${netIncome}`,
            ownFunds: `${ownFunds}`,
            interestRate: variableOrFix,
            realEstateAgent: `${withAgentCosts}`,
        })}`,
        {
            headers: { Accept: 'application/json' },
            signal: controller.signal,
        },
    )
    clearTimeout(timeoutId)

    return response.json() as Promise<BudgetResponse>
}
