import { useCallback, useEffect, useState } from 'react'
import { Navigator } from '@bbx/search-journey/common/Navigators'
import { CheckboxStateType, getValueId } from '@bbx/search-journey/sub-domains/search/lib/navigator-functions'

export const useMultiSelectCheckboxState = (navigator?: Navigator) => {
    const [checkboxState, setCheckboxState] = useState<CheckboxStateType>(getSelectedValuesFromNavigator(navigator))

    useEffect(() => {
        setCheckboxState(getSelectedValuesFromNavigator(navigator))
    }, [navigator])

    const handleChange = useCallback(
        (id: string, checked: boolean) => {
            let newState
            if (checked) {
                newState = {
                    ...checkboxState,
                    [id]: checked,
                }
            } else {
                const { [id]: _omit, ...newStateWithoutPreviousValue } = checkboxState
                newState = newStateWithoutPreviousValue
            }

            setCheckboxState(newState)
            return newState
        },
        [checkboxState],
    )

    return {
        checkboxState,
        handleChange,
        setCheckboxState,
    }
}

const getSelectedValuesFromNavigator = (navigator?: Navigator) => {
    const initialCheckboxState: Record<string, boolean> = {}
    if (navigator) {
        navigator.selectedValues.forEach((selectedValue) => (initialCheckboxState[getValueId(selectedValue)] = true))
    }
    return initialCheckboxState
}
