import React, { Fragment, FunctionComponent, ReactNode } from 'react'
import { PossibleNavigatorValue, SelectedNavigatorValue } from '@bbx/search-journey/common/Navigators'
import { css } from 'styled-components'
import { Box } from '@wh-components/core/Box/Box'
import { formatNumber } from '@wh/common/chapter/lib/formatter'
import { Text } from '@wh-components/core/Text/Text'
import { TestProps } from '@wh-components/core/common'

export interface NavigatorValueProps extends TestProps {
    value: PossibleNavigatorValue | (SelectedNavigatorValue & { hits?: number })
    showHitsLabel?: boolean
    disabled?: boolean
    id: string
    href?: string | null
    'aria-labelledby': string
}

export const NavigatorValue: FunctionComponent<NavigatorValueProps> = ({
    testId,
    value,
    showHitsLabel = true,
    disabled = false,
    id,
    href,
    'aria-labelledby': ariaLabelledBy,
}) => {
    const possibleValueButtonId = `navigator-title-${id}-${value.label.replace(/\s/g, '')}`
    const combinedAriaLabelledBy = [ariaLabelledBy, possibleValueButtonId].filter(Boolean).join(' ')
    const hits: number = value.hits ?? 0

    return !href ? (
        <Box
            id={possibleValueButtonId}
            testId={testId}
            color="palette.verydarkgrey"
            fontSize="m"
            textAlign="left"
            aria-labelledby={combinedAriaLabelledBy}
        >
            <PossibleLabelContent label={value.label} showHitsLabel={showHitsLabel} disabled={disabled} hits={hits} />
        </Box>
    ) : (
        <a
            id={possibleValueButtonId}
            data-testid={testId ? testId : ''}
            aria-labelledby={combinedAriaLabelledBy}
            tabIndex={-1}
            onClick={(e) => {
                e.preventDefault()
                // workaround so a click on the `<a>` tag gets propagated to the `<label>` and gets further propagated to the `<input>` element referenced by the `forHtml` prop
                e.stopPropagation()
                e.currentTarget.parentElement?.click()
            }}
            href={href}
            css={css`
                text-decoration: none;
            `}
        >
            <PossibleLabelContent label={value.label} showHitsLabel={showHitsLabel} disabled={disabled} hits={hits} />
        </a>
    )
}

export const PossibleLabelContent: FunctionComponent<{
    label: ReactNode
    showHitsLabel: boolean | undefined
    disabled: boolean
    hits?: number
}> = ({ hits, label, showHitsLabel, disabled }) => {
    return (
        <Fragment>
            <Text
                breakWord={true}
                paddingRight="s"
                color={disabled ? 'palette.lightgrey' : 'palette.verydarkgrey'}
                css={css`
                    &:hover {
                        text-decoration: underline;
                    }
                `}
            >
                {label}
            </Text>

            {showHitsLabel && (hits ?? 0) > 0 && (
                <Text aria-label={`mit ${hits} Treffern`} breakWord={true} fontSize="s" color="palette.elephant">
                    {formatNumber(hits)}
                </Text>
            )}
        </Fragment>
    )
}
