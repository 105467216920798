import React, { Fragment, FunctionComponent, useEffect, useRef, useState } from 'react'
import { Box, BoxProps } from '@wh-components/core/Box/Box'
import { NavigatorLabel } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/NavigatorLabel'
import { RangeNavigator, RangeNavigatorValue } from '@bbx/search-journey/common/Navigators'
import { ComboInput } from '@bbx/common/components/FormElements/ComboInput/ComboInput'
import { SearchCallback } from '@bbx/search-journey/sub-domains/search/components/common/common/SearchCallback'
import { InputSize } from '@wh-components/core/common'
import { isRangeNavigatorAvailable } from '@bbx/search-journey/sub-domains/search/lib/navigator-functions'
import { css } from 'styled-components'
import { ResponsiveValue } from '@wh-components/system'
import { InputProps } from '@wh-components/core/FormElements/Input/Input'
import { ResetAllButton } from '../ResetAllButton/ResetAllButton'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import { useDebouncedCallback } from 'use-debounce'
import { SearchId } from '@bbx/common/api/dtos/search-id'
import { ButtonWithLinkStyle } from '@wh-components/core/Button/ButtonWithLinkStyle'
import { useFeatureToggle } from '@wh/common/chapter/components/FeatureToggleProvider/FeatureToggleProvider'
import { useIsInView } from '@wh/common/chapter/hooks/useIsInView'
import { useBudgetCalculatorTagging } from '@bbx/search-journey/sub-domains/search/components/common/common/BudgetCalculator/useBudgetCalculatorTagging'
import { BudgetCalculatorModal } from '@bbx/search-journey/sub-domains/search/components/common/common/BudgetCalculator/BudgetCalculatorModal'

export interface RangeNavigatorProps {
    navigator: RangeNavigator
    onSearch: SearchCallback
    abortRequest: () => void
    enableAlternativeRange?: boolean
    mode?: 'from' | 'to' | 'both'
    label?: string
    id?: string
    placeholderFrom?: string
    placeholderTo?: string
    prependFrom?: InputProps['prepend']
    prependTo?: InputProps['prepend']
    size?: ResponsiveValue<InputSize>
    formatInput?: boolean
    disabled?: boolean
    taggingData: TaggingData
    searchId?: SearchId | undefined
}

export const RangeNavigatorComponent: FunctionComponent<RangeNavigatorProps & BoxProps> = ({
    navigator,
    onSearch,
    abortRequest,
    enableAlternativeRange = false,
    mode = 'both',
    label = navigator.label,
    id = navigator.id,
    placeholderFrom = 'von',
    placeholderTo = 'bis',
    size = {
        phone: 'large',
        tablet: 'medium',
    },
    prependTo,
    prependFrom,
    formatInput = true,
    disabled = false,
    searchId,
    taggingData,
    ...props
}) => {
    const values = navigator.selectedValues[0]?.urlParamRepresentationForValue
    const selectedValueFrom = values?.filter((value) => value.navigatorUrlParameterType === 'RANGE_FROM')[0]?.value ?? ''
    const selectedValueTo = values?.filter((value) => value.navigatorUrlParameterType === 'RANGE_TO')[0]?.value ?? ''
    const [valueFrom, setValueFrom] = useState<string>(selectedValueFrom)
    const [valueTo, setValueTo] = useState<string>(selectedValueTo)
    const selfRef = useRef<HTMLDivElement>(null)
    const enabledBudgetCalculatorInFilter = useFeatureToggle('enableBudgetCalculatorInFilterRealEstateResultList')
    const [budgetCalculatorModalOpen, setBudgetCalculatorModalOpen] = useState(false)
    const visibilityTrackingRef = useRef<HTMLButtonElement>(null)
    const [isInView] = useIsInView(visibilityTrackingRef, '-100px', undefined, true)
    const [budgetCalcLinkClicked, setBudgetCalculatorLinkClicked] = useState<boolean>(false)
    const [budgetCalcSubmitted, setBudgetCalculatorSubmitted] = useState<boolean>(false)
    const { onClick, onBudgetCalcSubmit } = useBudgetCalculatorTagging(isInView, budgetCalcLinkClicked, budgetCalcSubmitted, taggingData)
    const setToPriceToBudget = (price: string) => {
        if (!budgetCalcSubmitted) {
            setBudgetCalculatorSubmitted(true)
        }
        setValueTo(price)
        setBudgetCalculatorModalOpen(false)
        onBudgetCalcSubmit()
        abortRequest?.()
        debouncedSubmit()
    }

    const onSubmit = async (values2: { from: string; to: string }) => {
        if (!navigator) {
            return
        }
        if (values2.from === selectedValueFrom && values2.to === selectedValueTo) {
            return
        }

        const paramNameFrom = navigator.urlConstructionInformation.urlParams[0].urlParameterName
        const paramNameTo = navigator.urlConstructionInformation.urlParams[1].urlParameterName

        const from = removeNonDigits(values2.from)
        const to = removeNonDigits(values2.to)

        const additionalParams: Record<string, string> = {}

        if (from) {
            additionalParams[paramNameFrom] = removeNonDigits(from)
        }
        if (to) {
            additionalParams[paramNameTo] = removeNonDigits(to)
        }

        // if `from` is bigger than `to` and both are set, swap them around
        if (from && to && Number(from) > Number(to)) {
            additionalParams[paramNameFrom] = to
            additionalParams[paramNameTo] = from
        }

        await onSearch(navigator.urlConstructionInformation.baseUrl, additionalParams)
    }

    const isThisNavigatorFocussed = () => selfRef.current?.contains(document.activeElement)

    useEffect(() => {
        if (!selectedValueFrom || !valueFrom || !isThisNavigatorFocussed()) {
            setValueFrom(selectedValueFrom)
        }
        if (!selectedValueTo || !valueTo || !isThisNavigatorFocussed()) {
            setValueTo(selectedValueTo)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedValueFrom, selectedValueTo])

    useEffect(() => {
        setValueFrom(selectedValueFrom)
        setValueTo(selectedValueTo)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [enableAlternativeRange])

    const debouncedSubmit = useDebouncedCallback(() => {
        onSubmit({ from: valueFrom, to: valueTo })
    }, 450)

    if (mode === 'both' && navigator && !isRangeNavigatorAvailable(navigator)) {
        return null
    }

    const handleChangeAndSubmitFrom = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setValueFrom(e.target.value)
        abortRequest?.()
        debouncedSubmit()
    }

    const handleChangeAndSubmitTo = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setValueTo(e.target.value)
        abortRequest?.()
        debouncedSubmit()
    }

    const rangeNavigator = navigator as RangeNavigator | undefined

    const width = mode === 'both' ? '50%' : '100%'

    const showBudgetCalculatorLink =
        enabledBudgetCalculatorInFilter &&
        navigator.id === 'price' &&
        searchId &&
        (searchId === SearchId.Flat_Sale || searchId === SearchId.House_Sale || searchId === SearchId.Plots)

    return (
        <Box testId={`navigator-${id}-wrapper`} {...props} ref={selfRef}>
            {mode === 'both' && (
                <Box display="flex" justifyContent="space-between">
                    <NavigatorLabel>{label}</NavigatorLabel>
                    {navigator.selectedValues.length >= 1 && (
                        <ResetAllButton
                            navigatorId={navigator.id}
                            onSearch={onSearch}
                            contextLink={navigator.resetAllInformation?.resetAllUrl}
                        />
                    )}
                </Box>
            )}

            <form
                name={`navigator-${id}`}
                onSubmit={(e) => {
                    e.preventDefault()
                }}
                autoComplete="off"
            >
                <input
                    type="submit"
                    tabIndex={-1}
                    css={css`
                        /** ie 11 disables sumbit via enter when input type sumbit is display none */
                        height: 0;
                        width: 0;
                        opacity: 0;
                        padding: 0;
                        border: 0;
                        display: block;
                    `}
                />
                <Box
                    marginTop={mode === 'both' ? 'xs' : undefined}
                    display="flex"
                    css={css`
                        > *:not(:last-child) {
                            margin-right: ${(p) => p.theme.space.s}px;
                        }
                    `}
                >
                    {(mode === 'both' || mode === 'from') && (
                        <Box width={width}>
                            <ComboInput
                                items={
                                    rangeNavigator
                                        ? removeBeliebig(
                                              enableAlternativeRange
                                                  ? rangeNavigator.alternativeRanges[0].navigatorFromValues
                                                  : rangeNavigator.navigatorFromValues,
                                          )
                                        : []
                                }
                                formatInput={formatInput}
                                idPrefix={`navigator-${id}-from`}
                                testIdPrefix={`navigator-${id}-from`}
                                name="from"
                                size={size}
                                prepend={prependFrom}
                                aria-label={`${label} von`}
                                placeholder={placeholderFrom}
                                disabled={disabled || !navigator.urlConstructionInformation.urlParams.length}
                                value={valueFrom}
                                onChangeInput={handleChangeAndSubmitFrom}
                                onChangeSelect={handleChangeAndSubmitFrom}
                            />
                        </Box>
                    )}
                    {(mode === 'both' || mode === 'to') && (
                        <Box width={width}>
                            <ComboInput
                                items={
                                    rangeNavigator
                                        ? removeBeliebig(
                                              enableAlternativeRange
                                                  ? rangeNavigator.alternativeRanges[0].navigatorToValues
                                                  : rangeNavigator.navigatorToValues,
                                          )
                                        : []
                                }
                                formatInput={formatInput}
                                idPrefix={`navigator-${id}-to`}
                                testIdPrefix={`navigator-${id}-to`}
                                name="to"
                                size={size}
                                prepend={prependTo}
                                aria-label={`${label} bis`}
                                placeholder={placeholderTo}
                                disabled={disabled || !navigator.urlConstructionInformation.urlParams.length}
                                value={valueTo}
                                onChangeInput={handleChangeAndSubmitTo}
                                onChangeSelect={handleChangeAndSubmitTo}
                            />
                        </Box>
                    )}
                </Box>
            </form>
            {showBudgetCalculatorLink && (
                <Fragment>
                    <ButtonWithLinkStyle
                        ref={visibilityTrackingRef}
                        onClick={() => {
                            setBudgetCalculatorModalOpen(true)
                            setBudgetCalculatorLinkClicked(true)
                            onClick()
                        }}
                    >
                        Kaufbudget berechnen
                    </ButtonWithLinkStyle>
                    <BudgetCalculatorModal
                        isOpen={budgetCalculatorModalOpen}
                        onRequestClose={() => {
                            setBudgetCalculatorModalOpen(false)
                        }}
                        taggingData={taggingData}
                        setToPrice={setToPriceToBudget}
                    />
                </Fragment>
            )}
        </Box>
    )
}

const removeBeliebig = (values: RangeNavigatorValue[]) => {
    return values.filter((value) => value.value !== '*')
}

const removeNonDigits = (value: string) => {
    return value.replace(/\./g, '')
}
